import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../api/api';
import { Heading, Box, Button, Spinner, Textarea, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';
import SearchCanon from './SearchCanon'; // Import SearchCanon component
import { stripHtmlAndDecode } from '../utils/textUtils'; // Import the new utility function
import createCanon from '../utils/createCanon'; // Import the createCanon utility
import '../App.css';

const EditCanonPost = () => {
  const { id } = useParams();
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const [context, setContext] = useState(''); // New state for context
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [instructions, setInstructions] = useState(''); // New state for instructions
  const [draftModalOpen, setDraftModalOpen] = useState(false); // State for draft modal visibility
  const [versionOne, setVersionOne] = useState(''); // New state for version one
  const [inventions, setInventions] = useState([]); // New state for inventions array
  const [isGenerating, setIsGenerating] = useState(false);
  const toast = useToast();

  const handleSaveNewDraft = async (content) => {
    try {
      await api.post(`/canon_update/${id}`, { content });
      alert('Post updated successfully!');
    } catch (error) {
      console.error('Error updating canon post:', error);
    }
  };

  const addInventionToCanon = async (inventionTitle, inventionContent) => {
    try {
      await createCanon(inventionTitle, inventionContent); // Use createCanon to send data to the API
      toast({
        title: "Canon Entry added.",
        description: "Your new canon entry has been successfully added.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error adding the canon entry.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };


  // Pass updateContext function to SearchCanon component
  const updateContext = (newContent) => {
    setContext((prevContext) => `${prevContext}\n${newContent}`);
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await api.get(`/canon_post/${id}`);
        setContent(stripHtmlAndDecode(response.data.content));
        setTitle(response.data.title); // Set the title
      } catch (error) {
        console.error('Error fetching post:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  const sendGenerationInfo = async () => {
    setIsGenerating(true);
    try {
      const generationResponse = await api.post(`/create_draft/${id}`, { content, context, instructions, title });

      // Parse the JSON string in `content` to an object
      const rawContent = generationResponse.data.choices[0].message.content;
      console.log('GenerationResponse.data: ', generationResponse.data);
      let data;
      try {
        data = JSON.parse(rawContent); // Parse the JSON string into an object
      } catch (error) {
        console.error('Failed to parse content as JSON:', error);
        data = {}; // Fallback to an empty object if parsing fails
      }


      setVersionOne(data.content_version_one || "No version one generated");
      setInventions(Array.isArray(data.inventions) ? data.inventions : []);
      setDraftModalOpen(true);
      console.log('Draft created:', data);
      console.log('Inventions:', data.inventions);
      console.log('Version 1:', data.content_version_one);
    } catch (error) {
      console.error('Error creating draft:', error);
      alert("Failed to generate draft. Please try again.");
    } finally {
      setIsGenerating(false);
    }
  };
  

  if (loading) return (
  <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
    <Spinner size="xl" color="teal.500" />
    <div>Loading...</div>
  </Box>
);

  return (
    <Box>
      <Heading as="h1" size="2xl" textAlign="center" color="white">Generate Post Revision</Heading>
      <Heading as="h2" size="lg" textAlign="center" color="gray.300" mt={2}>{stripHtmlAndDecode(title)}</Heading> {/* Display the post title */}
      <Heading as="h3" size="md" color="gray.300" mt={4}>Content</Heading>
      <Textarea
  value={content}
  onChange={(e) => setContent(e.target.value)}
    placeholder="No content currently set for this entry."
  size="md"
  mt={2}
  color="white"
  bg="gray.700"
/>
      <Heading as="h3" size="md" color="gray.300" mt={4} display="inline-block" mr={2}>Context</Heading>
      <Button colorScheme="teal" size="sm" onClick={() => setIsModalOpen(true)}>
        Search to Add Context
      </Button>
      <Textarea
  value={context}
  onChange={(e) => setContext(e.target.value)}
  placeholder="Enter context here..."
  size="md"
  mt={2}
  color="white"
  bg="gray.700"
/>
      <Heading as="h3" size="md" color="gray.300" mt={4}>More Instructions (optional)</Heading>
      <Textarea
  value={instructions}
  onChange={(e) => setInstructions(e.target.value)}
  placeholder="Enter instructions here..."
  size="md"
  mt={2}
  color="white"
  bg="gray.700"
/>

<Button
  colorScheme="teal"
  size="lg"
  mt={4}
  onClick={sendGenerationInfo}
  isLoading={isGenerating}
>
  Generate Draft
</Button>


    {/* Modal for context search */}
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent minW="50%">
          <ModalHeader>Search for Context</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SearchCanon contextUsage={'add_to_revision'} updateContext={updateContext} />
          </ModalBody>
          </ModalContent>
      </Modal>

      <Modal isOpen={draftModalOpen} onClose={() => setDraftModalOpen(false)}>
        <ModalOverlay />
        <ModalContent minW="50%">
          <ModalHeader>Draft Created</ModalHeader>
          <ModalCloseButton />
          <ModalBody bg="black">
            <Box>
              <Heading as="h3" size="md" color="gray.300">Draft Details</Heading>
              <Box color="black" mt={2}>
                <Box dangerouslySetInnerHTML={{ __html: versionOne }} />
              </Box>
              <Box color="white" mt={2}>
                <strong>Inventions:</strong>
                {inventions.length > 0 ? (
                  inventions.map((inv, idx) => (
                    <Box key={idx} mt={2}>
                      <strong>Name:</strong> {inv.name || 'N/A'} <br/>
                      <strong>Proposed Facts:</strong> {inv.proposed_facts || 'N/A'} <br/>
                      <strong>{inv.real_or_imagined}</strong> <br/>
                      <Button colorScheme="teal" size="sm" onClick={() => 
                        {
                          addInventionToCanon(inv.name, inv.proposed_facts);
                        }

                      }>Create Canon Draft Entry</Button>
                    </Box>
                  ))
                ) : (
                  'No inventions generated'
                )}
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={() => handleSaveNewDraft(versionOne)}>Save Draft</Button>
            <Button colorScheme="yellow" onClick={() => setDraftModalOpen(false)}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Box>


  );
};

export default EditCanonPost;