import { extendTheme } from '@chakra-ui/react';
import { withProse } from '@nikolovlazar/chakra-ui-prose';

const theme = extendTheme({
  components: {
    Spinner: {
      baseStyle: {
        size: 'xl',
        color: 'white', // Set the default color for all Spinners
      },
    },
    List: {
      baseStyle: {
        padding: 0,
        borderBottom: '1px solid white',
      },
    },
  },
});

export default theme; 