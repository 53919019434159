import React, { useState, useEffect, useRef } from 'react';
import { Box, Text, Button, Input, VStack } from '@chakra-ui/react';
import api from '../api/api';
import TypingAnimation from './TypingAnimation';
import calculateDelay from '../utils/calculateDelay';
import splitMessage from '../utils/splitMessage';
import replacePlaceholders from '../utils/replacePlaceholders';
import getChat from './chatSteps';

const Chat = () => {
  const [step, setStep] = useState(0);
  const [userResponse, setUserResponse] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [fullPlayerName, setFullPlayerName] = useState('');
  const [quiz, setQuiz] = useState('');
  const [narrative, setNarrative] = useState('');
  const [facts, setFacts] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [givenName, setGivenName] = useState('');
  const [chatTerminated, setChatTerminated] = useState(false);
  const [chatSteps, setChatSteps] = useState([]);

  // A unusually formatted display of the current date and time.
  const currentDate = new Date().toLocaleString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  });

  

  const chatBoxRef = useRef(null); // Create a ref for the chat box

  async function initializeChat() {
    const retreivedChatSteps = await getChat(0);  
    return retreivedChatSteps;
  }


  // Use a useEffect hook to fetch the chat strings from the API
  useEffect(() => {
    async function fetchChatSteps() {
      const chatSteps = await initializeChat();
      setChatSteps(chatSteps);
    }
    fetchChatSteps();
  }, []);


  const handleResponse = async (response) => {
    setChatHistory((prev) => [...prev, { speaker: 'User', message: response }]);
    setUserResponse('');

    const lowerCaseResponse = response.toLowerCase();

    const validResponsesStep0 = [
      "yes",
      "yes, i can",
      "absolutely",
      "got it",
      "perfect",
      "clear",
      "loud and clear",
      "no problem",
      "it's fine",
      "yes, clearly",
      "looks good",
      "all set",
      "it worked",
      "definitely",
      "perfectly",
      "i see it",
      "it's visible",
      "i can",
      "yes, working",
      "absolutely, yes",
      "it's clear",
      "sure",
      "fine",
      "confirmed",
      "visible",
      "understood",
      "right",
      "done",
      "seen",
      "affirmative",
      "yup",
      "yep",
      "yeah",
      "sure can",
      "sure thing"
    ];

    const validResponsesStep1 = [
      "yes",
      "i'm in",
      "absolutely",
      "count me in",
      "for sure",
      "definitely",
      "i'm game",
      "you bet",
      "i'm with you",
      "no doubt",
      "totally in",
      "sure",
      "yup",
      "yep",
      "yeah",
    ];

    const validResponsesStep3 = [
      "yes",
      "absolutely",
      "sure",
      "okay",
      "definitely",
      "of course",
      "agreed",
      "fine",
      "right",
      "indeed",
      "alright",
      "affirmative",
      "yeah",
      "yep",
      "gotcha",
      "certainly",
      "confirmed",
      "surely"
    ];

    if (step === 0) {
      if (validResponsesStep0.includes(lowerCaseResponse)) {
        await proceedToNextStep();
      } else {
        provideClarification();
      }
    } else if (step === 1) {
      if (validResponsesStep1.includes(lowerCaseResponse)) {
        await proceedToNextStep();
      } else {
        provideClarification();
      }
    } else if (step === 2) {
      await handleNameResponse(response);
    } else if (step === 3) {
      if (validResponsesStep3.includes(lowerCaseResponse)) {
        await handleCoverStoryResponse();
      } else {
        provideClarification();
      }
    } else if (step === 4) {
      if (validResponsesStep3.includes(lowerCaseResponse)) { // This is a bit of a hack using the same list as step 3
        await proceedToNextStep();
      } else {
        provideClarification();
      }
    } else if (step === 5) {
      await handleEmailResponse(response);
    } else {
      await proceedToNextStep();
    }
  };

  const proceedToNextStep = async () => {
    const nextStep = step + 1;
    let nextMessage = chatSteps[nextStep];

    const messages = splitMessage(nextMessage);
    setIsTyping(true);
    displayMessages(messages, nextStep);
    console.log('PTNS: This is the current step:' + step);
  };

  const provideClarification = () => {
    setIsTyping(true);
    setTimeout(() => {
      setChatHistory((prev) => [...prev, { speaker: 'Harlow', message: getClarificationMessage() }]);
      setIsTyping(false);
    }, calculateDelay(getClarificationMessage()));
  };

  const getClarificationMessage = () => {
    if (step === 0) {
      return 'Please confirm if you can read this by responding with "Yes".';
    } else if (step === 1) {
      return 'I need your help to keep Cascadia safe. Please respond with "Yes" if you are willing to help.';
    } else if (step === 3) {
      return 'It\'s important for your cover story. Please respond with "Yes".';
    }
    return 'I didn\'t understand you. Please respond appropriately.';
  };

  const handleNameResponse = async (response) => {
    console.log('HNR: Setting full player name.');
    setFullPlayerName(response);
    setGivenName(response);
    setUserResponse('');
    const { result, familyName } = await replacePlaceholders(chatSteps[3], { playername: response }, fullPlayerName);
    const messages = splitMessage(result);
    setFullPlayerName(`${response} ${familyName}`);
    setFamilyName(familyName);
    setIsTyping(true);
    displayMessages(messages, step + 1);
    console.log('HNR: This is the current step:' + step);
  };

  const handleCoverStoryResponse = async () => {
    setIsTyping(true);
    console.log('HCSR: Setting cover story now.');
    const { result, quiz, facts, narrative  } = await replacePlaceholders(chatSteps[4], { coverstory: fullPlayerName }, fullPlayerName);
    const messages = splitMessage(result);
    setNarrative(narrative);
    setFacts(facts);
    setQuiz(quiz);
    displayMessages(messages, step + 1);
    console.log('HCSR: This is the current step:' + step);
  }

  // Method to handle email response from the user
  const handleEmailResponse = async (response) => {
    console.log('HER: Setting email address now with response:', response);
    const generatedPassword = generatePassword();
    const savedStatus = await savePlayerData(response, generatedPassword);
    console.log('HER: Saved status:', savedStatus);
    setIsTyping(true);
    displayMessages(['Okay, I have sent you a link to the login for the Nexus system. You will be able to move forward from there! We will talk more soon!'], step + 1);
    console.log('HER: This is the current step:' + step);
    setChatTerminated(true);
  };

  // Method to generate a random secure password of length 15 to 25 characters
  const generatePassword = () => {
    const length = Math.floor(Math.random() * 11) + 15;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+';
    let password = '';
    for (let i = 0; i < length; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return password;
  };

  const displayMessages = (messages, nextStep) => {
    let delay = 0;
    messages.forEach((msg, index) => {
      setTimeout(() => {
        setChatHistory((prev) => [...prev, { speaker: 'Harlow', message: msg.trim() }]);
        if (index === messages.length - 1) {
          setIsTyping(false); // Stop typing animation after the last message
          setStep(nextStep); // Move to the next step after all messages are displayed
          console.log('DM: This is the current step:' + step);
        }
      }, delay);
      delay += calculateDelay(msg);
    });
  };

  // Method to handle saving all of the player's data to the database
  const savePlayerData = async (enteredEmail, generatedPassword) => {
    console.log('Saving player data to the database.');
    try {
      const response = await api.post('/save_player_data', {
        full_player_name: fullPlayerName,
        given_name: givenName,
        family_name: familyName,
        email: enteredEmail,
        password: generatedPassword,
        quiz: quiz,
        narrative: narrative,
        facts: facts
      });
      console.log('Received response from save_player_data:', response.data);
    } catch (error) {
      console.error('Error saving player data:', error);
    }
  };

  useEffect(() => {
    if (step === 0 && chatHistory.length === 0) {
      setIsTyping(true); // Show typing animation on initial load
      setTimeout(() => {
        setChatHistory([{ speaker: 'Harlow', message: 'Did it work? Can you read this?' }]);
        setIsTyping(false); // Hide typing animation after message is displayed
      }, calculateDelay('Did it work? Can you read this?'));
    }
  }, [step, chatHistory.length]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [chatHistory]); // Add effect to scroll to bottom on chatHistory change

  return (
    <Box
      height="68vh"
      maxHeight="68vh"
      overflowY="auto"
      backgroundColor="gray.800"
      color={"#000"}
      p={4}
      borderRadius="md"
      ref={chatBoxRef} // Add the ref to the chat box
    >
      <VStack spacing={4} align="left">
        {chatHistory.map((entry, index) => (
          <Text key={index} alignSelf={entry.speaker === 'Harlow' ? 'flex-start' : 'flex-end'} bg={entry.speaker === 'Harlow' ? 'gray.200' : 'teal.200'} p={2} borderRadius="md" color={"#000"}>
            <strong>{entry.speaker}: </strong>{entry.message}
          </Text>
        ))}
        {isTyping && <TypingAnimation />}
        {step < chatSteps.length && !isTyping && !chatTerminated && (
          <Box>
            <Input
              color={"#fff"}
              value={userResponse}
              onChange={(e) => setUserResponse(e.target.value)}
              placeholder="Type your response here"
              onKeyDown={async (e) => {
                if (e.key === 'Enter') {
                  await handleResponse(e.target.value);
                }
              }}
            />
            <Button onClick={async () => await handleResponse(userResponse)}>Send</Button>
          </Box>
        )}
        {chatTerminated && (
          <Text alignSelf="center" bg="gray.200" color={"red"} p={2} borderRadius="md">
            <strong>Chat Terminated </strong>
            <Text as="span" color={"#000"}>{currentDate}</Text>
          </Text>
        )}
      </VStack>
    </Box>
  );
};

export default Chat;