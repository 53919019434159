import axios from 'axios';
import api from '../api/api';

// Array of strings for the initial chat (chat 0)
const initialChat = [
    'Did it work? Can you read this?',
    'It’s a major miracle. I don’t have much time to talk right now, but I’ll explain as quickly as I can. I know it sounds crazy but I’m from a parallel universe, and I need your help. I’m a member of a group devoted to keeping my country, Cascadia, safe. There are those who wish to destroy our nation: from the outside and even from the inside. We don’t know who we can trust. That’s where you come in. You’ll be our secret eyes and ears. And because you’re an outsider we can rely on you to be on our side. At least, that is the hope! Are you in?',
    'Great. Our first task is to create a believable cover story for you. It’s always best to start with a kernel of truth. Let’s start with your first or given name. What is it?',
    'Good to meet you, [playername]. We’ll give you a family name similar to something in this universe that we can build a backstory off of. Are you okay with [suggestedname]?',
    'Okay, based on that name, here’s a potential back story that won’t arouse suspicion. [coverstory] You will need to memorize this and be ready to use it at a moment’s notice. Can you do that?',
    'Your overall goal is to embed yourself in Cascadian culture in the worlds of finance and politics as quickly as possible so that we can be alerted to any nefarious activities as quickly as possible. I have taken some cash from an account that nobody should miss, but I have to get it back in there in one week. Your first task is to begin your mastery of the financial markets in Cascadia. We need you to make as much money as quickly as possible so that I can return the amount I borrowed. It’s probably simplest to start with the Universal Commodities Market. Once you’ve done that you’ll be ready for your next task and I’ll be in touch again. I’m giving you access to the standard personal Paralux Nexus console common in our universe. I will need your electronic mail address to send you the access code you need. What is it?',
    'Okay, I have sent you a link to the login for the Nexus system. You will be able to move forward from there! We will talk more soon!'
  ];

// Function to fetch chat strings from the API
async function fetchChat(chatId) {
    try {
        const response = await axios.get(`/api/chat/${chatId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching chat:', error);
        return [];
    }
}

// Function to get chat strings based on chatId
async function getChat(chatId) {
    if (chatId === 0) {
        return initialChat;
    } else {
        return await fetchChat(chatId);
    }
}

export default getChat;