import axios from 'axios';

// Fetch the API URL from the environment variables
// 
let baseURL;

switch (window.location.hostname) {
  case 'prlx.sbs':
    baseURL = 'https://sl1.paraluxunlimited.com/api/v1';
    break;
  case 'nexus.paraluxunlimited.com':
    baseURL = 'https://sl1.paraluxunlimited.com/api/v1';
    break;
  default:
    baseURL = 'https://sl1.paraluxunlimited.com/api/v1';
}

const api = axios.create({
  baseURL: baseURL, // Adjust the base URL as needed
  leonardoURL: 'https://cloud.leonardo.ai/api/rest/v1/', // Add the Leonardo URL
  bigThinkURL: 'https://haveabigthink.com/wp-json/wp/v2/posts?per_page=100', // Add the Big Think URL
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;