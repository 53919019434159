import React, { useState, useEffect } from 'react';
import { Box, Heading, List, ListItem, Text, VStack, Spinner, Button, Flex } from '@chakra-ui/react';
import { jwtDecode } from 'jwt-decode';
import api from '../api/api';

const Comms = () => {
  const [stories, setStories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [playerId, setPlayerId] = useState(null);
  const [reloadingSingleStory, setReloadingSingleStory] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('nexus_login_token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setPlayerId(decodedToken.sub);
      } catch (error) {
        console.error("Invalid token:", error);
        localStorage.removeItem('nexus_login_token');
      }
    } else {
      console.error('No token found');
    }
  }, []);

  const handleEdgeClick = async (playerId, edgeId, fromNodeId, toNodeId, storyStateId, storyId) => {
    try {
      setReloadingSingleStory(true);
      const response = await api.post('/player_edge_choices', {
        player_edge_choice: {
          player_id: playerId,
          edge_id: edgeId,
          from_node_id: fromNodeId,
          to_node_id: toNodeId,
          player_story_state_id: storyStateId,
        }
      });

      console.log('Player Edge Choice Created:', response.data);

      // Fetch the updated story node and its edges
      const updatedStoryResponse = await api.get(`/stories/${storyId}/nodes/${toNodeId}`);
      const updatedStory = updatedStoryResponse.data;

      // Update the specific story in the stories array
      setStories((prevStories) =>
        prevStories.map((story) =>
          story.story_id === storyId
            ? {
                ...story,
                node_display_text: updatedStory.node.display_text,
                node_id: updatedStory.node.id,
                edges: updatedStory.edges || [], // Update edges
              }
            : story
        )
      );

      setReloadingSingleStory(false);
    } catch (error) {
      console.error('Error creating player edge choice:', error);
      setReloadingSingleStory(false);
      throw error;
    }
  };

  useEffect(() => {
    const fetchStoryInfo = async () => {
      try {
        const response = await api.get(`/players/${playerId}/current_story_info`);
        setStories(response.data);
        setIsLoading(false);
      } catch (err) {
        setError('Failed to fetch story information');
        setIsLoading(false);
      }
    };

    if (playerId) {
      fetchStoryInfo();
    }
  }, [playerId]);

  if (isLoading) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}><Spinner size="xl" /></div>;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  return (
    <VStack spacing={2} width="100%" align="start" p={2}>
      <Heading as="h2" size="lg" alignSelf="center">Comms</Heading>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Spinner size="xl" />
        </Box>
      ) : (
        <List spacing={3} width="100%">
          {stories.map((story, index) => (
            <ListItem key={index} borderBottom="1px solid white" p={4}>
              <Flex width="100%">
                <Box width="25%" pr={4}>
                  <Text fontStyle="italic" mb={2}>{story.character_name}</Text>
                  <Box 
                    width="100%" 
                    height="100px" 
                    bg="gray.200" 
                    mb={2} 
                    display="flex" 
                    justifyContent="center" 
                    alignItems="center"
                  >
                    <Text>Thumbnail</Text>
                  </Box>
                </Box>
                <Box width="75%">
                  <Text mb={4}>{story.node_display_text}</Text>
                </Box>
              </Flex>
              {story.edges && Array.isArray(story.edges) && story.edges.length > 0 && !reloadingSingleStory && (
                <Box ml="30%">
                  <List spacing={2}>
                    {story.edges.map((edge, edgeIndex) => (
                      <ListItem borderBottom="none" key={edgeIndex}>
                        <Button 
                          onClick={() => handleEdgeClick(playerId, edge.edge_id, edge.from_node_id, edge.to_node_id, story.story_state_id, story.story_id)}
                          colorScheme="teal"
                          size="md"
                          width="auto"
                        >
                          {edge.edge_display_text}
                        </Button>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
            </ListItem>
          ))}
        </List>
      )}
    </VStack>
  );
};

export default Comms; 