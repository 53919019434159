// src/components/LoginPage.js
import React, { useState } from 'react';
import { Box, Heading, Input, Button, VStack } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import api from '../api/api';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    try {
      setLoading(true);
      const response = await api.post('/login', { player: { email, password } });
      console.log('Login response:', response);
      const { token } = response.data;
      console.log('Token:', token);
      localStorage.setItem('nexus_login_token', token); // Save the token to local storage
      navigate('/console'); // Navigate to the game console
    } catch (error) {
      console.error('Error logging in:', error);
      if (error.response && error.response.status !== 200) {
        setLoading(false);
        setErrorMessage('Login failed. Please check your email and password.');
      } else {
        setLoading(false);
        setErrorMessage('An unexpected error occurred. Please try again later.');
      }
    }
  };

  return (
    <Box bg="#FFFFFF" height="80vh" maxHeight="80vh" textAlign="center" py={10} px={6}>
      <VStack spacing={4}>
        <Heading as="h1" size="xl" mb={5}>
          Login
        </Heading>
        {errorMessage && (
          <Box color="red.500" mb={4}>
            {errorMessage}
          </Box>
        )}
        <Input
          color="black"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <Input
          color="black"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <Button colorScheme="teal" onClick={handleLogin}>
        {loading ? <Spinner size="sm" /> : 'Login'}
        </Button>
      </VStack>
    </Box>
  );
};

export default LoginPage;
