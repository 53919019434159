import React, { useState } from 'react';
import { Box, Text, Button, keyframes } from '@chakra-ui/react';
import ConnectionAnimation from './ConnectionAnimation';
import Chat from './InitialChat';

// Define keyframes for the button blink effect
const blink = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`;

const LandingPage = () => {
  const [animationComplete, setAnimationComplete] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    setAccepted(true);
    // Navigate to another route if needed
    // navigate('/another-route');
  };

  return (
    <Box height="80vh" maxHeight="80vh" textAlign="left" py={10} px={6} backgroundColor={"#000000"}>
      <ConnectionAnimation onComplete={() => setAnimationComplete(true)} />
        <Box //This is the main content box
            backgroundColor={"#000000"} py={2} px={2}>
        {animationComplete && !accepted && (
            <Box mt={5}>
            <Text fontSize="lg" mb={3} color={"#ffffff"}>
                You have an incoming comm request from Harlow Constance.
            </Text>
            <Button
                colorScheme="teal"
                onClick={handleAccept}
                animation={`${blink} 2s infinite`}
            >
                Accept
            </Button>
            </Box>
        )}
        {accepted && (
            <Box mt={5}>
                <Chat />
            {/* Add any other content or components to display after acceptance here */}
            </Box>
        )}
      </Box>
    </Box>
  );
};

export default LandingPage;
