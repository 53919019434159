import React, { useState, useEffect } from 'react';
import { Box, Heading, List, ListItem, Button, Input, Textarea, VStack, Spinner, Text, Select } from '@chakra-ui/react';
import { fetchStories, createStory, updateStory, deleteStory } from '../../utils/adminStoryManager';
import { useNavigate } from 'react-router-dom';
import api from '../../api/api';

const AdminStories = () => {
  const [stories, setStories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newStory, setNewStory] = useState({ title: '', description: '', metadata: {} });
  const [characters, setCharacters] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    loadStories();
  }, []);

  const loadStories = async () => {
    try {
      const data = await fetchStories();
      setStories(data);
    } catch (error) {
      setError('Error fetching stories');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCharacters();
  }, []);

  const fetchCharacters = async () => {
    try {
      const response = await api.get('/characters');
      setCharacters(response.data);
    } catch (error) {
      console.error('Error fetching characters:', error);
    }
  };

  const handleCreateStory = async () => {
    try {
      const createdStory = await createStory(newStory);
      setStories([...stories, createdStory]);
      setNewStory({ title: '', description: '', metadata: {} });
    } catch (error) {
      console.error('Error creating story:', error);
    }
  };

  const handleUpdateStory = async (id, updatedData) => {
    try {
      const updatedStory = await updateStory(id, updatedData);
      setStories(stories.map(story => (story.id === id ? updatedStory : story)));
    } catch (error) {
      console.error('Error updating story:', error);
    }
  };

  const handleDeleteStory = async (id) => {
    try {
      await deleteStory(id);
      setStories(stories.filter(story => story.id !== id));
    } catch (error) {
      console.error('Error deleting story:', error);
    }
  };

  if (isLoading) {
    return <Spinner size="xl" />;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  return (
    <VStack align="start" spacing={4}>
      <Heading as="h2" size="lg">Manage Stories</Heading>
      <Box>
        <Input
          placeholder="Title"
          value={newStory.title}
          onChange={(e) => setNewStory({ ...newStory, title: e.target.value })}
        />
        <Textarea
          placeholder="Description"
          value={newStory.description}
          onChange={(e) => setNewStory({ ...newStory, description: e.target.value })}
        />
        <Select
          placeholder="Select Character"
          value={newStory.characterId}
          onChange={(e) => setNewStory({ ...newStory, characterId: e.target.value })}
        >
          {characters.map((character) => (
            <option key={character.id} value={character.id}>
              {character.name}
            </option>
          ))}
        </Select>
        <Button onClick={handleCreateStory}>Create Story</Button>
      </Box>
      <List spacing={3}>
        {stories.map((story) => (
          <ListItem key={story.id} onClick={() => navigate(`/admin/stories/${story.id}`)}>
            <Text fontWeight="bold">{story.title}</Text>
            <Text>{story.description}</Text>
            <Button onClick={() => handleUpdateStory(story.id, { title: 'Updated Title', description: 'Updated Description' })}>Update</Button>
            <Button onClick={() => handleDeleteStory(story.id)}>Delete</Button>
          </ListItem>
        ))}
      </List>
    </VStack>
  );
};

export default AdminStories; 