// src/components/Characters.js
import React, { useState, useEffect } from 'react';
import { Box, Heading, Input, Button, List, ListItem } from '@chakra-ui/react';
import api from '../api/api';

const Characters = () => {
  const [characters, setCharacters] = useState([]);
  const [name, setName] = useState('');

  useEffect(() => {
    fetchCharacters();
  }, []);

  const fetchCharacters = async () => {
    try {
      const response = await api.get('/characters');
      setCharacters(response.data);
    } catch (error) {
      console.error('Error fetching characters:', error);
    }
  };

  const handleAddCharacter = async () => {
    try {
      const response = await api.post('/characters', { character: { name } });
      setCharacters([...characters, response.data]);
      setName('');
    } catch (error) {
      console.error('Error adding character:', error);
    }
  };

  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading as="h1" size="xl" mb={5}>
        Characters
      </Heading>
      <Input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Character Name"
        mb={3}
      />
      <Button colorScheme="teal" onClick={handleAddCharacter} mb={5}>
        Add Character
      </Button>
      <List spacing={3}>
        {characters.map((character) => (
          <ListItem key={character.id}>{character.name}</ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Characters;
