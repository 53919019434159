import React, { useState, useEffect } from 'react';
import { Box, Heading, List, ListItem, Text, VStack, Spinner, HStack, Image, Input, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from '@chakra-ui/react';
import api from '../api/api';
import searchCanon from '../utils/searchCanon';
import fetchCanon from '../utils/fetchCanon';
import { useDisclosure } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prose } from '@nikolovlazar/chakra-ui-prose';

// Utility function to strip HTML tags
const stripHtml = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

const Compendium = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPost, setSelectedPost] = useState(null);
  const [results, setResults] = useState([]);

  // Get the current day of the week
  const currentDay = new Date().toLocaleDateString('en-US', { weekday: 'long' });


  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await api.get('/dashboard_data');
        setDashboardData(response.data);
      } catch (err) {
        setError('Failed to fetch dashboard data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  const handleSearch = async () => {
    if (dashboardData) {
        setIsSearching(true);
        try {
          const data = await searchCanon(query);
          setResults(data);
        } catch (error) {
          console.error('Search failed:', error);
        } finally {
          setIsSearching(false);
        }
      onOpen(); // Open modal when search is performed
    }
  };

  const handlePostClick = async (post) => {
    try {
      const postData = await fetchCanon(post.wp_id);
      setSelectedPost(postData);
      onOpen();
    } catch (error) {
      console.error('Error fetching post:', error);
    }
  };

  // Add this function to handle going back to search results
  const handleBackToResults = () => {
    setSelectedPost(null);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  return (
    <VStack spacing={4} width="100%" align="start" justify="flex-start">
      <VStack spacing={4} width="100%" align="start" justify="flex-start">
        <Heading alignSelf="center" as="h2" size="lg">Paralux Compendium</Heading>
        <HStack spacing={4} width="100%">
          <Input
            placeholder="Search the compendium"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
          {isSearching ? (
            <Box width="10%">
              <Spinner size="sm" />
            </Box>
          ) : (
            <Box width="10%">
              <Button mb="1rem" onClick={handleSearch} colorScheme="teal">
                Search
              </Button>
            </Box>
          )}
        </HStack>
        <VStack borderTop="10px solid white" marginTop="1rem" width="100%">
          <Heading as="h2" size="xl" mt={4}>{currentDay}&apos;s Featured Post</Heading>
          <Heading as="h3" size="lg">{stripHtml(dashboardData.daily_featured_post.title)}</Heading>
          <Image maxW="30%" src={dashboardData.daily_featured_post.featured_media_url} alt={dashboardData.daily_featured_post.title} />
          
          <Prose>
          <Box 
            dangerouslySetInnerHTML={{ __html: dashboardData.daily_featured_post.content.replace(/<a /g, '<a target="_blank" ') }}
            sx={{
              a: {
                color: 'teal.500',
                textDecoration: 'underline',
                fontWeight: 'bold',
                _hover: {
                  color: 'teal.700',
                },
              },
            }}
          />
          </Prose>
        </VStack>
      </VStack>
      <HStack borderTop="10px solid white" width="100%" align="top" justify="flex-start">
      <Box width="50%">
        <Heading as="h2" size="lg" mt={4}>Deep Dives</Heading>
        <List spacing={3}>
          {dashboardData.longest_posts.map(post => (
            <ListItem 
              key={post.wp_id}
              onClick={() => handlePostClick(post)}
              cursor="pointer"
              _hover={{ bg: 'gray.700' }}
              borderBottom="1px solid white"
              borderRadius="md"
              p={2}
            >
              <HStack width="100%" justifyContent="space-between">
                <Box width="100%">
                  <Heading as="h4" size="sm">{stripHtml(post.title)}</Heading>
                  <Text>{stripHtml(post.excerpt)}</Text>
                </Box>
                {post.featured_media_url && post.featured_media_url !== '' && post.featured_media_url !== null && (
                  <Box width="50%">
                    <Image maxW="100%" src={post.featured_media_url} alt={post.title} />
                  </Box>
                )}
              </HStack>
            </ListItem>
          ))}
        </List>
      </Box>
      <VStack spacing={4} width="50%" align="start" justify="flex-start">
      <Box width="100%">
              <Heading as="h2" size="lg" mt={4}>Explore Our World</Heading>
              <List spacing={3}>
          {dashboardData.random_published_posts.map(post => (
                <ListItem 
                key={post.wp_id}
                onClick={() => handlePostClick(post)}
                cursor="pointer"
                _hover={{ bg: 'gray.700' }}
                borderBottom="1px solid white"
                borderRadius="md"
                p={2}
                >
                  <HStack width="100%" justifyContent="space-between">
                      <Box width="100%">
                          <Heading as="h4" size="sm">{stripHtml(post.title)}</Heading>
                          <Text>{stripHtml(post.excerpt)}</Text>
                      </Box>
                      {post.featured_media_url && (
                      <Box width="50%">
                          <Image maxW="100%" src={post.featured_media_url} alt={post.title} />
                      </Box>
                      )}
                  </HStack>
              </ListItem>
          ))}
              </List>
          </Box>
          <Box width="100%">
              <Heading as="h2" size="lg" mt={4}>Recently Updated</Heading>
              <List spacing={3}>
                  {dashboardData.most_recent_posts.map(post => (
                      <ListItem 
                      key={post.wp_id}
                      onClick={() => handlePostClick(post)}
                      cursor="pointer"
                      _hover={{ bg: 'gray.700' }}
                      borderBottom="1px solid white"
                      borderRadius="md"
                      p={2}
                      >
                          <HStack width="100%" justifyContent="space-between">
                              <Box width="100%">
                                  <Heading as="h4" size="sm">{stripHtml(post.title)}</Heading>
                                  <Text>{stripHtml(post.excerpt)}</Text>
                              </Box>
                              {post.featured_media_url && (
                              <Box width="50%">
                                  <Image maxW="100%" src={post.featured_media_url} alt={post.title} />
                              </Box>
                              )}
                          </HStack>
                      </ListItem>
                  ))}
              </List>
          </Box>
      </VStack>
    </HStack>
    <Modal isOpen={isOpen} onClose={() => {
      onClose();
      setSelectedPost(null);
    }} size="full">
      <ModalOverlay />
      <ModalContent maxH="80vh" overflowY="auto">
        <ModalHeader>{selectedPost ? stripHtml(selectedPost.title) : 'Search Results for ' + query}</ModalHeader>
        <ModalCloseButton size="lg" color="red" border="1px solid red" borderRadius="full"/>
        <ModalBody>
          {selectedPost ? (
            <VStack align="stretch" spacing={4}>
                {results.length != 0 && (
                    <Button 
                    leftIcon={<FontAwesomeIcon icon={['fas', 'arrow left']} size="xl" />}
                    onClick={handleBackToResults}
                    alignSelf="flex-start"
                    colorScheme="teal"
                    size="sm"
                >
                    Back to Search Results
                </Button>
                )}
              <HStack bg="black" width="100%" justifyContent="space-between">
                <Box 
                  bg="black"
                  dangerouslySetInnerHTML={{ __html: selectedPost.content.replace(/<a /g, '<a target="_blank" ') }}
                  sx={{
                    a: {
                      color: 'teal.500',
                      textDecoration: 'underline',
                      fontWeight: 'bold',
                      _hover: {
                        color: 'teal.700',
                      },
                    },
                  }}
                />
                    {selectedPost.featured_media_url && selectedPost.featured_media_url !== '' && selectedPost.featured_media_url !== null && (
                    
                      <Image maxW="30%" mr="1rem" src={selectedPost.featured_media_url} alt={selectedPost.title} />
                    
                  )}
              </HStack>
            </VStack>
          ) : (
            <List spacing={3}>
              {results.map(post => (
                <ListItem 
                  key={post.wp_id} 
                  onClick={() => handlePostClick(post)}
                  cursor="pointer"
                  _hover={{ bg: 'gray.700' }}
                  bg="black"
                  p={2}
                  borderRadius="md"
                  borderBottom="1px solid white"
                >
                  <HStack width="100%" justifyContent="space-between">
                    <Box width="100%">
                      <Heading as="h4" size="sm">{stripHtml(post.title)}</Heading>
                      <Text>{stripHtml(post.excerpt)}</Text>
                    </Box>
                    {post.featured_media_url && post.featured_media_url !== '' && post.featured_media_url !== null && (
                      <Box width="50%">
                        <Image maxW="100%" src={post.featured_media_url} alt={post.title} />
                      </Box>
                    )}
                  </HStack>
                </ListItem>
              ))}
            </List>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  </VStack>
);
};

export default Compendium; 