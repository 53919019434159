import React, { useState, useEffect } from 'react';
import { Box, Heading, List, ListItem, Text, VStack, Spinner, HStack, Image, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Button } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../api/api';

// Utility function to strip HTML tags
const stripHtml = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || '';
};

const BigThink = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await api.get(api.defaults.bigThinkURL);
        setPosts(response.data);
      } catch (err) {
        setError('Failed to fetch posts');
        console.error('Error fetching posts:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const handlePostClick = (post) => {
    setSelectedPost(post);
    onOpen();
  };

  const handleBackToList = () => {
    setSelectedPost(null);
    onClose();
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  return (
    <VStack spacing={4} width="100%" align="start" justify="flex-start">
      <Heading alignSelf="center" as="h2" size="lg">Big Think Magazine</Heading>
      <Heading alignSelf="center" as="h3" size="md">Cascadia's Premiere News Magazine</Heading>
      <List spacing={3} width="100%">
        {posts.map(post => (
          <ListItem 
            key={post.id}
            onClick={() => handlePostClick(post)}
            cursor="pointer"
            _hover={{ bg: 'gray.700' }}
            borderBottom="1px solid white"
            borderRadius="md"
            p={2}
          >
            <HStack width="100%" justifyContent="space-between">
              <Box width="100%">
                <Heading as="h4" size="sm">{stripHtml(post.title.rendered)}</Heading>
                <Text>{stripHtml(post.excerpt.rendered)}</Text>
              </Box>
              {post.yoast_head_json.og_image[0].url && (
                <Box width="30%">
                  <Image maxW="100%" src={post.yoast_head_json.og_image[0].url} alt={post.title.rendered} />
                </Box>
              )}
            </HStack>
          </ListItem>
        ))}
      </List>

      <Modal isOpen={isOpen} onClose={() => {
        onClose();
        setSelectedPost(null);
      }} size="full">
        <ModalOverlay />
        <ModalContent maxH="80vh" overflowY="auto">
          <ModalHeader>{selectedPost && stripHtml(selectedPost.title.rendered)}</ModalHeader>
          <ModalCloseButton size="lg" color="red" border="1px solid red" borderRadius="full"/>
          <ModalBody>
            {selectedPost && (
              <VStack align="stretch" spacing={4}>
                <Button 
                  leftIcon={<FontAwesomeIcon icon={['fas', 'arrow-left']} size="xl" />}
                  onClick={handleBackToList}
                  alignSelf="flex-start"
                  colorScheme="teal"
                  size="sm"
                >
                  Back to Posts
                </Button>
                <HStack bg="black" width="100%" justifyContent="space-between">
                  <Box 
                    bg="black"
                    dangerouslySetInnerHTML={{ 
                      __html: selectedPost.content.rendered.replace(/<a /g, '<a target="_blank" ')
                    }}
                    sx={{
                      a: {
                        color: 'teal.500',
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                        _hover: {
                          color: 'teal.700',
                        },
                      },
                    }}
                  />
                  {selectedPost.featured_media_url && (
                    <Image maxW="30%" mr="1rem" src={selectedPost.featured_media_url} alt={selectedPost.title.rendered} />
                  )}
                </HStack>
              </VStack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default BigThink;
