/**
 * CanonResultDetails Component
 * 
 * This React component displays and processes canon post details for image generation.
 * Key functionalities:
 * - Fetches and displays a specific canon post's details using the post ID from URL params
 * - Loads available AI models for image generation
 * - Provides an editable textarea containing the post's title and content
 * - Allows users to select an AI model from available options
 * - Handles image generation by sending the content to an API endpoint
 * - Displays the generated image once available
 * 
 * The component uses Chakra UI for styling and includes loading states
 * to provide feedback during API operations.
 */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading, Text, Spinner, Textarea, Button } from '@chakra-ui/react';
import api from '../api/api';
import { stripHtmlAndDecode } from '../utils/textUtils'; // Import the utility function

const CanonResultDetails = () => {
  const { id } = useParams();
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [textareaContent, setTextareaContent] = useState(''); // New state for Textarea content
  const [isGenerating, setIsGenerating] = useState(false); // State for managing spinner visibility
  const [imageUrl, setImageUrl] = useState(''); // New state for image URL
  const [title, setTitle] = useState(''); // New state for title
  const [resultId, setResultId] = useState(''); // New state for id
  const [models, setModels] = useState([]); // New state for models
  const [selectedModel, setSelectedModel] = useState('5c232a9e-9061-4777-980a-ddc8e65647c6'); // State for selected model with default ID
  const [draftPrompt, setDraftPrompt] = useState(''); // New state for draft prompt
  const [isSaving, setIsSaving] = useState(false); // Add state for tracking save status

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await api.get('/models');
        console.log('Models API response:', response.data);
        setModels(Array.isArray(response.data.models.custom_models) ? response.data.models.custom_models : []);
      } catch (error) {
        console.error('Error fetching models:', error);
        alert('Failed to fetch models.');
      }
    };

    const fetchResultDetails = async () => {
      try {
        const response = await api.get(`/canon_post/${id}`);
        setResult(response.data);
        setTitle(response.data.title);
        setResultId(response.data.id);
        const content = `${response.data.title}\n\n${stripHtmlAndDecode(response.data.content.replace(/\n/g, ' '))}`;
        setTextareaContent(content);
        
        // Create draft prompt after content is set
        const promptResponse = await api.post('/prompts/image_draft_prompt', {
          content: content,
        });
        setDraftPrompt(promptResponse.data.prompt);
        console.log('Draft prompt:', promptResponse.data.prompt);
        console.log('Prompt Response Data:', promptResponse.data);
        setTextareaContent(promptResponse.data.prompt);
        
      } catch (error) {
        console.error('Error fetching result details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchResultDetails();
    fetchModels();
  }, [id]);

  const handleSendData = async () => {
    setIsGenerating(true); // Show spinner
    const dataToSend = {
      id: id,
      content: textareaContent,
      model_id: selectedModel, // Add selected model ID
    };

    try {
      const response = await api.post('/prompts', dataToSend);
      
      if (response.data.success) {
        setImageUrl(response.data.data.image_url);
      } else {
        // Handle different error cases
        if (response.data.data?.generation_id) {
          alert(response.data.error + " Generation ID: " + response.data.data.generation_id);
        } else {
          alert(response.data.error);
        }
      }
    } catch (error) {
      console.error('Error sending data:', error);
      alert('Failed to send data.');
    } finally {
      setIsGenerating(false); // Hide spinner
    }
  };

  const handleSaveAsFeatured = async () => {
    setIsSaving(true);
    try {
      await api.post(`/canon_post/${id}/set_featured_image`, {
        image_url: imageUrl
      });
      alert('Featured image saved successfully!');
    } catch (error) {
      console.error('Error saving featured image:', error);
      alert('Failed to save featured image.');
    } finally {
      setIsSaving(false);
    }
  };

  if (loading) return <Spinner size="xl" color="teal.500" />;

  return (
    <Box p={4} color="white">
      <Heading as="h1" size="xl">{title}</Heading> {/* Use state variable */}
      <Text mt={2} fontSize="lg">Prompt for Image Generation:</Text>
      <Textarea
        mt={4}
        height="400px"
        value={textareaContent} // Use the state variable
        onChange={(e) => setTextareaContent(e.target.value)} // Add onChange handler
        color="black"
        bg="white"
        p={10}
      />
      <Box mt={4}>
        <Heading as="h2" size="md">Select a Model:</Heading>
        <select onChange={(e) => setSelectedModel(e.target.value)} value={selectedModel} style={{ color: 'black' }}>
          <option key="default" value="5c232a9e-9061-4777-980a-ddc8e65647c6">
            Leonardo Vision XL (Photo-Realistic) - Default
          </option>
          {models.length > 0 ? (
            models.map((model) => (
              <option key={model.id} value={model.id}>
                {model.name}
              </option>
            ))
          ) : (
            <option disabled>No models available</option>
          )}
        </select>
      </Box>
      <button onClick={handleSendData} style={{ marginTop: '20px', padding: '10px 20px', backgroundColor: 'teal', color: 'white', border: 'none', borderRadius: '5px' }}>
        Generate Image
      </button>
      <Box mt={4}>
        {isGenerating && <Spinner size="md" color="teal.500" mt={4} />}
        {imageUrl && (
          <>
            <img src={imageUrl} alt="Generated" style={{ marginTop: '20px', maxWidth: '100%' }} />
            <Button
              onClick={handleSaveAsFeatured}
              isLoading={isSaving}
              loadingText="Saving..."
              colorScheme="teal"
              mt={4}
            >
              Save as Featured Image
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default CanonResultDetails;