import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Heading, List, ListItem, VStack, Text, Spinner, HStack } from '@chakra-ui/react';
import { getArrayOfInfo, initializePostCounts } from '../utils/dashboardDetails';

const AdminSection = () => {
  const [countThere, setCountThere] = useState(0);
  const [countHere, setCountHere] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        await initializePostCounts();
        const info = getArrayOfInfo();
        console.log('Fetched Info:', info); // Debugging log
        setCountThere(info.CountThere);
        setCountHere(info.CountHere);
        setIsLoading(false);
      } catch (error) {
        console.error('Error initializing post counts:', error);
      }
    };

    fetchCounts();
  }, []);

  return (
    <VStack overflow={isLoading ? 'hidden' : 'auto'}>
      <Box color="white">
        <Heading as="h1" size="lg" mb={4}>Admin</Heading>
        <nav>
          <HStack align="start" spacing={8}>
            <Box>
              <List spacing={3}>
                <ListItem><Link to="/admin/stories" _hover={{ color: 'gray.300' }}>Stories</Link></ListItem>
                <ListItem><Link to="/admin/characters" _hover={{ color: 'gray.300' }}>Characters</Link></ListItem>
                <ListItem><Link to="/admin/chats" _hover={{ color: 'gray.300' }}>Chats</Link></ListItem>
                <ListItem><Link to="/admin/messages" _hover={{ color: 'gray.300' }}>Messages</Link></ListItem>
                <ListItem><Link to="/admin/commodities" _hover={{ color: 'gray.300' }}>Commodities</Link></ListItem>
                <ListItem><Link to="/admin/chat_gpt_prompts" _hover={{ color: 'gray.300' }}>Chat GPT Prompts</Link></ListItem>
                <ListItem><Link to="/admin/news_narratives" _hover={{ color: 'gray.300' }}>News Narratives</Link></ListItem>
                <ListItem><Link to="/admin/news" _hover={{ color: 'gray.300' }}>News</Link></ListItem>
                <ListItem><Link to="/admin/players" _hover={{ color: 'gray.300' }}>Players</Link></ListItem>
              </List>
            </Box>
            <Box>
              <Heading as="h2" size="md" mt={4}>Canon Management</Heading>
              <List spacing={3}>
                <ListItem><Link to="/admin/search_canon" _hover={{ color: 'gray.300' }}>Search Canon</Link></ListItem>
                <ListItem><Link to="/admin/canon_articles" _hover={{ color: 'gray.300' }}>List All Canon Articles</Link></ListItem>
                <ListItem><Link to="/admin/canon_draft_articles" _hover={{ color: 'gray.300' }}>List Draft Canon Articles</Link></ListItem>
                <ListItem><Link to="/admin/canon_random_draft_articles" _hover={{ color: 'gray.300' }}>List Random Draft Canon Articles</Link></ListItem>
                <ListItem><Link to="/admin/canon_random_published_articles" _hover={{ color: 'gray.300' }}>List Random Published Canon Articles</Link></ListItem>
                <ListItem><Link to="/admin/add_canon_entry" _hover={{ color: 'gray.300' }}>Create a Canon Entry</Link></ListItem>
              </List>
            </Box>
          </HStack>
        </nav>
      </Box>
      <Box color="white">
        <Heading as="h2">Dashboard</Heading>
        {isLoading ? (
          <Spinner size="xl" />
        ) : (
          <Box>
            <Text>Canon Articles in WP: {countThere}</Text>
            <Text>Canon Articles in DB: {countHere}</Text>
            <Text>Delta: {countThere - countHere}</Text>
          </Box>
        )}
      </Box>
    </VStack>
  );
};

export default AdminSection;