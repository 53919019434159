import api from '../api/api';

const fetchCanon = async (postId) => {
  try {
    const response = await api.get(`/canon_post/${postId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching canon entry:', error);
    throw error;
  }
};

export default fetchCanon;