import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Button,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Textarea,
  Select,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  Checkbox,
} from '@chakra-ui/react';
import { fetchNodeByStoryId, createNode, updateNode, deleteNode } from '../../utils/nodeManager';
import { fetchStoryById } from '../../utils/adminStoryManager';
import { fetchEdgesByNodeId, createEdge, updateEdge, deleteEdge } from '../../utils/edgeManager';

const StoryDetail = () => {
  const { storyId } = useParams();
  const { isOpen: isNodeModalOpen, onOpen: onNodeModalOpen, onClose: onNodeModalClose } = useDisclosure();
  const { isOpen: isEdgeModalOpen, onOpen: onEdgeModalOpen, onClose: onEdgeModalClose } = useDisclosure();
  const {
    isOpen: isEditNodeModalOpen,
    onOpen: onEditNodeModalOpen,
    onClose: onEditNodeModalClose
  } = useDisclosure();
  const { 
    isOpen: isPreviewModalOpen, 
    onOpen: onPreviewModalOpen, 
    onClose: onPreviewModalClose 
  } = useDisclosure();
  
  const [story, setStory] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [newNode, setNewNode] = useState({
    name: '',
    display_text: '',
    story_id: storyId,
    metadata: {}
  });

  const [newEdge, setNewEdge] = useState({
    story_id: storyId,
    from_node_id: '',
    to_node_id: '',
    label: '',
    conditions: '{}',
    effects: '{}',
    order: 0
  });

  const [editingNode, setEditingNode] = useState(null);
  const [editingEdge, setEditingEdge] = useState(null);
  const {
    isOpen: isEditEdgeModalOpen,
    onOpen: onEditEdgeModalOpen,
    onClose: onEditEdgeModalClose
  } = useDisclosure();

  const [currentPreviewNode, setCurrentPreviewNode] = useState(null);

  const commonConditions = [
    { key: 'required_item', label: 'Required Item' },
    { key: 'min_level', label: 'Minimum Level Required' },
    { key: 'max_level', label: 'Maximum Level Allowed' },
    { key: 'required_faction', label: 'Required Faction' },
    { key: 'required_reputation', label: 'Required Reputation' }
  ];

  // Effects are the actions that happen when the edge is taken. This should apply for a financial game.
  const commonEffects = [
    { key: 'add_item', label: 'Add Item to Inventory' },
    { key: 'remove_item', label: 'Remove Item from Inventory' },
    { key: 'add_reputation', label: 'Add Reputation Points' },
    { key: 'remove_reputation', label: 'Remove Reputation Points' },
    { key: 'add_experience', label: 'Add Experience Points' },
    { key: 'set_faction', label: 'Set Faction Alignment' },
    { key: 'add_money', label: 'Add Money' },
    { key: 'remove_money', label: 'Remove Money' },
    { key: 'add_stock', label: 'Add Stock' },
    { key: 'remove_stock', label: 'Remove Stock' },
    { key: 'add_commodity', label: 'Add Commodity' },
    { key: 'remove_commodity', label: 'Remove Commodity' }
  ];

  useEffect(() => {
    loadStoryNodesAndEdges();
  }, [storyId]);

  const loadStoryNodesAndEdges = async () => {
    try {
      setIsLoading(true);
      const [storyResponse, nodesResponse] = await Promise.all([
        fetchStoryById(storyId),
        fetchNodeByStoryId(storyId),
      ]);
      
      setStory(storyResponse);
      setNodes(nodesResponse);

      // Fetch edges for each node
      const allEdges = await Promise.all(
        nodesResponse.map(node => fetchEdgesByNodeId(node.id))
      );
      
      // Flatten the array of edge arrays into a single array
      setEdges(allEdges.flat());
    } catch (error) {
      setError('Error loading story details');
      console.error('Error loading story details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateNode = async () => {
    try {
      const createdNode = await createNode(newNode);
      setNodes([...nodes, createdNode]);
      setNewNode({
        name: '',
        display_text: '',
        story_id: storyId,
        metadata: {}
      });
      onNodeModalClose();
    } catch (error) {
      console.error('Error creating node:', error);
    }
  };

  const handleUpdateNode = async () => {
    try {
      const updatedNode = await updateNode(editingNode.id, {
        name: editingNode.name,
        display_text: editingNode.display_text
      });
      setNodes(nodes.map(node => node.id === editingNode.id ? updatedNode : node));
      setEditingNode(null);
      onEditNodeModalClose();
    } catch (error) {
      console.error('Error updating node:', error);
    }
  };

  const handleDeleteNode = async (nodeId) => {
    try {
      await deleteNode(nodeId);
      setNodes(nodes.filter(node => node.id !== nodeId));
    } catch (error) {
      console.error('Error deleting node:', error);
    }
  };

  const isValidJson = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleCreateEdge = async () => {
    try {
      const edgeData = {
        ...newEdge,
        conditions: JSON.parse(newEdge.conditions),
        effects: JSON.parse(newEdge.effects)
      };
      
      const createdEdge = await createEdge(edgeData);
      setEdges([...edges, createdEdge]);
      setNewEdge({
        story_id: storyId,
        from_node_id: '',
        to_node_id: '',
        label: '',
        conditions: '{}',
        effects: '{}',
        order: 0
      });
      onEdgeModalClose();
    } catch (error) {
      console.error('Error creating edge:', error);
    }
  };

  const handleUpdateEdge = async () => {
    try {
      const edgeData = {
        ...editingEdge,
        conditions: JSON.parse(editingEdge.conditions),
        effects: JSON.parse(editingEdge.effects)
      };
      const updatedEdge = await updateEdge(editingEdge.id, edgeData);
      setEdges(edges.map(edge => edge.id === editingEdge.id ? updatedEdge : edge));
      setEditingEdge(null);
      onEditEdgeModalClose();
    } catch (error) {
      console.error('Error updating edge:', error);
    }
  };

  const handleDeleteEdge = async (edgeId) => {
    try {
      await deleteEdge(edgeId);
      setEdges(edges.filter(edge => edge.id !== edgeId));
    } catch (error) {
      console.error('Error deleting edge:', error);
    }
  };

  const handleEditNodeClick = (node) => {
    setEditingNode({ ...node });
    onEditNodeModalOpen();
  };

  const handleEditEdgeClick = (edge) => {
    setEditingEdge({
      ...edge,
      conditions: JSON.stringify(edge.conditions, null, 2),
      effects: JSON.stringify(edge.effects, null, 2)
    });
    onEditEdgeModalOpen();
  };

  const findStartingNode = () => {
    const nodesWithIncomingEdges = new Set(edges.map(edge => edge.to_node_id));
    return nodes.find(node => !nodesWithIncomingEdges.has(node.id));
  };

  const getNodeEdges = (nodeId) => {
    return edges
      .filter(edge => edge.from_node_id === nodeId)
      .sort((a, b) => a.order - b.order);
  };

  if (isLoading) {
    return (
      <Box textAlign="center" py={10}>
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" py={10}>
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Box p={5}>
      {story && (
        <VStack align="start" spacing={6}>
          <Box w="100%">
            <HStack justify="space-between" w="100%">
              <Heading size="lg">{story.title}</Heading>
              <Button colorScheme="teal" onClick={() => {
                const startNode = findStartingNode();
                setCurrentPreviewNode(startNode);
                onPreviewModalOpen();
              }}>
                Preview Story
              </Button>
            </HStack>
            <Text mt={2}>{story.description}</Text>
            {story.character && story.character.name && (
              <Text>Associated Character: <b>{story.character.name}</b></Text>
            )}
          </Box>

          <Box w="100%">
            <HStack justify="space-between" mb={4}>
              <Heading size="md">Story Nodes</Heading>
              <Button colorScheme="blue" onClick={onNodeModalOpen}>
                Add New Node
              </Button>
            </HStack>

            <VStack align="start" spacing={4}>
              {nodes.map((node) => (
                <Box
                  key={node.id}
                  p={4}
                  borderWidth="1px"
                  borderRadius="lg"
                  w="100%"
                >
                  <HStack justify="space-between">
                    <VStack align="start">
                      <Text fontWeight="bold">{node.name}</Text>
                      <Text>{node.display_text}</Text>
                    </VStack>
                    <HStack>
                      <Button
                        size="sm"
                        onClick={() => handleEditNodeClick(node)}
                      >
                        Edit
                      </Button>
                      <Button
                        size="sm"
                        colorScheme="red"
                        onClick={() => handleDeleteNode(node.id)}
                      >
                        Delete
                      </Button>
                    </HStack>
                  </HStack>
                </Box>
              ))}
            </VStack>
          </Box>

          <Box w="100%">
            <HStack justify="space-between" mb={4}>
              <Heading size="md">Node Connections</Heading>
              <Button colorScheme="green" onClick={onEdgeModalOpen}>
                Add New Connection
              </Button>
            </HStack>

            <VStack align="start" spacing={4}>
              {edges.map((edge) => (
                <Box
                  key={edge.id}
                  p={4}
                  borderWidth="1px"
                  borderRadius="lg"
                  w="100%"
                >
                  <HStack justify="space-between" alignItems="flex-start">
                    <VStack align="start" spacing={2}>
                      <Text fontWeight="bold">
                        {nodes.find(n => n.id === edge.from_node_id)?.name} →{' '}
                        {nodes.find(n => n.id === edge.to_node_id)?.name}
                      </Text>
                      <Text>Choice: "{edge.label}"</Text>
                      <Text>Order: {edge.order}</Text>
                      {Object.keys(edge.conditions).length > 0 && (
                        <Box>
                          <Text fontWeight="semibold">Conditions:</Text>
                          <Text as="pre" fontSize="sm">
                            {JSON.stringify(edge.conditions, null, 2)}
                          </Text>
                        </Box>
                      )}
                      {Object.keys(edge.effects).length > 0 && (
                        <Box>
                          <Text fontWeight="semibold">Effects:</Text>
                          <Text as="pre" fontSize="sm">
                            {JSON.stringify(edge.effects, null, 2)}
                          </Text>
                        </Box>
                      )}
                    </VStack>
                    <HStack>
                      <Button
                        size="sm"
                        onClick={() => handleEditEdgeClick(edge)}
                      >
                        Edit
                      </Button>
                      <Button
                        size="sm"
                        colorScheme="red"
                        onClick={() => handleDeleteEdge(edge.id)}
                      >
                        Delete
                      </Button>
                    </HStack>
                  </HStack>
                </Box>
              ))}
            </VStack>
          </Box>
        </VStack>
      )}

      <Modal isOpen={isNodeModalOpen} onClose={onNodeModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Node</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Input
                color="black"
                placeholder="Node Name"
                value={newNode.name}
                onChange={(e) => setNewNode({ ...newNode, name: e.target.value })}
              />
              <Textarea
                color="black"
                placeholder="Display Text"
                value={newNode.display_text}
                onChange={(e) => setNewNode({ ...newNode, display_text: e.target.value })}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCreateNode}>
              Create
            </Button>
            <Button variant="ghost" onClick={onNodeModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isEdgeModalOpen} onClose={onEdgeModalClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Connection</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>From Node</FormLabel>
                <Select
                  color="black"
                  placeholder="Select starting node"
                  value={newEdge.from_node_id}
                  onChange={(e) => setNewEdge({ ...newEdge, from_node_id: e.target.value })}
                >
                  {nodes.map(node => (
                    <option key={node.id} value={node.id}>{node.name}</option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>To Node</FormLabel>
                <Select
                  color="black"
                  placeholder="Select ending node"
                  value={newEdge.to_node_id}
                  onChange={(e) => setNewEdge({ ...newEdge, to_node_id: e.target.value })}
                >
                  {nodes.map(node => (
                    <option key={node.id} value={node.id}>{node.name}</option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Label (Choice Text)</FormLabel>
                <Input
                  color="black"
                  placeholder="e.g., 'Accept the mission' or 'Decline'"
                  value={newEdge.label}
                  onChange={(e) => setNewEdge({ ...newEdge, label: e.target.value })}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Order</FormLabel>
                <NumberInput
                  min={0}
                  value={newEdge.order}
                  onChange={(valueString) => setNewEdge({ ...newEdge, order: parseInt(valueString) })}
                >
                  <NumberInputField color="black" />
                </NumberInput>
              </FormControl>

              <FormControl>
                <FormLabel>Conditions</FormLabel>
                <VStack align="start" spacing={2} w="100%">
                  {commonConditions.map(condition => (
                    <HStack key={condition.key} w="100%">
                      <Checkbox
                        isChecked={newEdge.conditions[condition.key] !== undefined}
                        onChange={(e) => {
                          const updatedConditions = { ...JSON.parse(newEdge.conditions) };
                          if (e.target.checked) {
                            updatedConditions[condition.key] = '';
                          } else {
                            delete updatedConditions[condition.key];
                          }
                          setNewEdge({
                            ...newEdge,
                            conditions: JSON.stringify(updatedConditions)
                          });
                        }}
                      >
                        {condition.label}
                      </Checkbox>
                      {newEdge.conditions[condition.key] !== undefined && (
                        <Input
                          size="sm"
                          placeholder={`Value for ${condition.label}`}
                          value={newEdge.conditions[condition.key]}
                          onChange={(e) => {
                            const updatedConditions = { ...JSON.parse(newEdge.conditions) };
                            updatedConditions[condition.key] = e.target.value;
                            setNewEdge({
                              ...newEdge,
                              conditions: JSON.stringify(updatedConditions)
                            });
                          }}
                        />
                      )}
                    </HStack>
                  ))}
                </VStack>
              </FormControl>

              <FormControl>
                <FormLabel>Effects</FormLabel>
                <VStack align="start" spacing={2} w="100%">
                  {commonEffects.map(effect => (
                    <HStack key={effect.key} w="100%">
                      <Checkbox
                        isChecked={newEdge.effects[effect.key] !== undefined}
                        onChange={(e) => {
                          const updatedEffects = { ...JSON.parse(newEdge.effects) };
                          if (e.target.checked) {
                            updatedEffects[effect.key] = '';
                          } else {
                            delete updatedEffects[effect.key];
                          }
                          setNewEdge({
                            ...newEdge,
                            effects: JSON.stringify(updatedEffects)
                          });
                        }}
                      >
                        {effect.label}
                      </Checkbox>
                      {newEdge.effects[effect.key] !== undefined && (
                        <Input
                          size="sm"
                          placeholder={`Value for ${effect.label}`}
                          value={newEdge.effects[effect.key]}
                          onChange={(e) => {
                            const updatedEffects = { ...JSON.parse(newEdge.effects) };
                            updatedEffects[effect.key] = e.target.value;
                            setNewEdge({
                              ...newEdge,
                              effects: JSON.stringify(updatedEffects)
                            });
                          }}
                        />
                      )}
                    </HStack>
                  ))}
                </VStack>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button 
              colorScheme="blue" 
              mr={3} 
              onClick={handleCreateEdge}
              isDisabled={!isValidJson(newEdge.conditions) || !isValidJson(newEdge.effects)}
            >
              Create Connection
            </Button>
            <Button variant="ghost" onClick={onEdgeModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isEditNodeModalOpen} onClose={onEditNodeModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Node</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Input
                color="black"
                placeholder="Node Name"
                value={editingNode?.name || ''}
                onChange={(e) => setEditingNode({ ...editingNode, name: e.target.value })}
              />
              <Textarea
                color="black"
                placeholder="Display Text"
                value={editingNode?.display_text || ''}
                onChange={(e) => setEditingNode({ ...editingNode, display_text: e.target.value })}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleUpdateNode}>
              Save Changes
            </Button>
            <Button variant="ghost" onClick={onEditNodeModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isEditEdgeModalOpen} onClose={onEditEdgeModalClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Connection</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>From Node</FormLabel>
                <Select
                  color="black"
                  value={editingEdge?.from_node_id || ''}
                  onChange={(e) => setEditingEdge({ ...editingEdge, from_node_id: e.target.value })}
                >
                  {nodes.map(node => (
                    <option key={node.id} value={node.id}>{node.name}</option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>To Node</FormLabel>
                <Select
                  color="black"
                  value={editingEdge?.to_node_id || ''}
                  onChange={(e) => setEditingEdge({ ...editingEdge, to_node_id: e.target.value })}
                >
                  {nodes.map(node => (
                    <option key={node.id} value={node.id}>{node.name}</option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Label (Choice Text)</FormLabel>
                <Input
                  color="black"
                  placeholder="e.g., 'Accept the mission' or 'Decline'"
                  value={editingEdge?.label || ''}
                  onChange={(e) => setEditingEdge({ ...editingEdge, label: e.target.value })}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Order</FormLabel>
                <NumberInput
                  min={0}
                  value={editingEdge?.order || 0}
                  onChange={(valueString) => setEditingEdge({ ...editingEdge, order: parseInt(valueString) })}
                >
                  <NumberInputField color="black" />
                </NumberInput>
              </FormControl>

              <FormControl>
                <FormLabel>Conditions</FormLabel>
                <VStack align="start" spacing={2} w="100%">
                  {commonConditions.map(condition => (
                    <HStack key={condition.key} w="100%">
                      <Checkbox
                        isChecked={editingEdge && JSON.parse(editingEdge.conditions)[condition.key] !== undefined}
                        onChange={(e) => {
                          if (!editingEdge) return;
                          const updatedConditions = { ...JSON.parse(editingEdge.conditions) };
                          if (e.target.checked) {
                            updatedConditions[condition.key] = '';
                          } else {
                            delete updatedConditions[condition.key];
                          }
                          setEditingEdge({
                            ...editingEdge,
                            conditions: JSON.stringify(updatedConditions)
                          });
                        }}
                      >
                        {condition.label}
                      </Checkbox>
                      {editingEdge && JSON.parse(editingEdge.conditions)[condition.key] !== undefined && (
                        <Input
                          color="black"
                          size="sm"
                          placeholder={`Value for ${condition.label}`}
                          value={JSON.parse(editingEdge.conditions)[condition.key]}
                          onChange={(e) => {
                            const updatedConditions = { ...JSON.parse(editingEdge.conditions) };
                            updatedConditions[condition.key] = e.target.value;
                            setEditingEdge({
                              ...editingEdge,
                              conditions: JSON.stringify(updatedConditions)
                            });
                          }}
                        />
                      )}
                    </HStack>
                  ))}
                </VStack>
              </FormControl>

              <FormControl>
                <FormLabel>Effects</FormLabel>
                <VStack align="start" spacing={2} w="100%">
                  {commonEffects.map(effect => (
                    <HStack key={effect.key} w="100%">
                      <Checkbox
                        isChecked={editingEdge && JSON.parse(editingEdge.effects)[effect.key] !== undefined}
                        onChange={(e) => {
                          if (!editingEdge) return;
                          const updatedEffects = { ...JSON.parse(editingEdge.effects) };
                          if (e.target.checked) {
                            updatedEffects[effect.key] = '';
                          } else {
                            delete updatedEffects[effect.key];
                          }
                          setEditingEdge({
                            ...editingEdge,
                            effects: JSON.stringify(updatedEffects)
                          });
                        }}
                      >
                        {effect.label}
                      </Checkbox>
                      {editingEdge && JSON.parse(editingEdge.effects)[effect.key] !== undefined && (
                        <Input
                          color="black"
                          size="sm"
                          placeholder={`Value for ${effect.label}`}
                          value={JSON.parse(editingEdge.effects)[effect.key]}
                          onChange={(e) => {
                            const updatedEffects = { ...JSON.parse(editingEdge.effects) };
                            updatedEffects[effect.key] = e.target.value;
                            setEditingEdge({
                              ...editingEdge,
                              effects: JSON.stringify(updatedEffects)
                            });
                          }}
                        />
                      )}
                    </HStack>
                  ))}
                </VStack>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button 
              colorScheme="blue" 
              mr={3} 
              onClick={handleUpdateEdge}
              isDisabled={
                !editingEdge?.conditions || 
                !editingEdge?.effects || 
                !isValidJson(editingEdge.conditions) || 
                !isValidJson(editingEdge.effects)
              }
            >
              Save Changes
            </Button>
            <Button variant="ghost" onClick={onEditEdgeModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isPreviewModalOpen} onClose={onPreviewModalClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Story Preview</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentPreviewNode ? (
              <VStack spacing={6} align="stretch">
                <Box 
                  p={4} 
                  borderWidth="1px" 
                  borderRadius="lg" 
                  bg="gray.700"
                >
                  <Text fontSize="lg">{currentPreviewNode.display_text}</Text>
                </Box>

                <VStack spacing={3} align="stretch">
                  {getNodeEdges(currentPreviewNode.id).map((edge) => {
                    const targetNode = nodes.find(n => n.id === edge.to_node_id);
                    return (
                      <Button
                        key={edge.id}
                        onClick={() => setCurrentPreviewNode(targetNode)}
                        variant="outline"
                        justifyContent="flex-start"
                        whiteSpace="normal"
                        textAlign="left"
                        height="auto"
                        p={4}
                      >
                        <VStack align="start" spacing={2} w="100%">
                          <Text>{edge.label}</Text>
                          {Object.keys(edge.conditions).length > 0 && (
                            <Text fontSize="sm" color="yellow.300">
                              Requires: {Object.entries(edge.conditions).map(([key, value]) => 
                                `${key}: ${value}`
                              ).join(', ')}
                            </Text>
                          )}
                          {Object.keys(edge.effects).length > 0 && (
                            <Text fontSize="sm" color="blue.300">
                              Effects: {Object.entries(edge.effects).map(([key, value]) => 
                                `${key}: ${value}`
                              ).join(', ')}
                            </Text>
                          )}
                        </VStack>
                      </Button>
                    );
                  })}
                </VStack>

                {getNodeEdges(currentPreviewNode.id).length === 0 && (
                  <Text color="gray.400" textAlign="center">
                    (End of story branch)
                  </Text>
                )}

                <Button 
                  mt={4} 
                  variant="ghost"
                  color="black"
                  onClick={() => {
                    const startNode = findStartingNode();
                    setCurrentPreviewNode(startNode);
                  }}
                >
                  Restart Story
                </Button>
              </VStack>
            ) : (
              <Text color="red.300">No starting node found. Create a node without incoming connections to begin the story.</Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onPreviewModalClose}>
              Close Preview
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default StoryDetail; 