import api from '../api/api';

/**
 * Fetches all stories from the API.
 * @returns {Promise<Array>} - A promise that resolves to an array of stories.
 */
export const fetchStories = async () => {
  try {
    const response = await api.get('/stories');
    return response.data;
  } catch (error) {
    console.error('Error fetching stories:', error);
    throw error;
  }
};

/**
 * Creates a new story in the API.
 * @param {Object} storyData - The story data to be created.
 * @param {string} storyData.title - The title of the story.
 * @param {string} storyData.description - The description of the story.
 * @param {Object} storyData.metadata - Additional metadata for the story.
 * @returns {Promise<Object>} - A promise that resolves to the created story.
 */
export const createStory = async (storyData) => {
  try {
    const response = await api.post('/stories', { story: storyData });
    return response.data;
  } catch (error) {
    console.error('Error creating story:', error);
    throw error;
  }
};

/**
 * Updates an existing story in the API.
 * @param {string} id - The ID of the story to update.
 * @param {Object} updatedData - The updated story data.
 * @param {string} [updatedData.title] - The updated title.
 * @param {string} [updatedData.description] - The updated description.
 * @returns {Promise<Object>} - A promise that resolves to the updated story.
 */
export const updateStory = async (id, updatedData) => {
  try {
    const response = await api.patch(`/stories/${id}`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error updating story:', error);
    throw error;
  }
};

/**
 * Deletes a story from the API.
 * @param {string} id - The ID of the story to delete.
 * @returns {Promise<void>} - A promise that resolves when the story is deleted.
 */
export const deleteStory = async (id) => {
  try {
    await api.delete(`/stories/${id}`);
  } catch (error) {
    console.error('Error deleting story:', error);
    throw error;
  }
};

/**
 * Fetches a specific story by ID from the API.
 * @param {string|number} id - The ID of the story to fetch.
 * @returns {Promise<Object>} - A promise that resolves to the story data.
 */
export const fetchStoryById = async (id) => {
  try {
    const response = await api.get(`/stories/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching story:', error);
    throw error;
  }
}; 