import React, { useState, useEffect } from 'react';
import { Box, Heading, List, ListItem, Spinner, VStack, Text } from '@chakra-ui/react';
import axios from 'axios';

const PlayerList = () => {
  const [players, setPlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await axios.get('https://sl1.paraluxunlimited.com/api/v1/players');
        setPlayers(response.data);
      } catch (err) {
        setError('Failed to fetch players');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlayers();
  }, []);

  if (isLoading) {
    return <Spinner size="xl" />;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  return (
    <VStack align="start" spacing={4}>
      <Heading as="h2" size="lg">Player List</Heading>
      <Box>
        <List spacing={3}>
          {players.map((player, index) => (
            <ListItem key={index}>
              <Text><strong>Name:</strong> {player.given_name} {player.family_name}</Text>
              <Text><strong>Email:</strong> {player.email}</Text>
              <Text><strong>Profile Data:</strong></Text>
              {player.profile_data ? (
                <Box pl={4}>
                  <Text><strong>Quiz:</strong></Text>
                  {player.profile_data.quiz ? (
                    <Box pl={4}>
                      <Text><strong>Question:</strong> {player.profile_data.quiz.question}</Text>
                      <Text><strong>Answer:</strong> {player.profile_data.quiz.answer}</Text>
                      <Text><strong>Options:</strong> {Array.isArray(player.profile_data.quiz.options) ? player.profile_data.quiz.options.join(', ') : 'No options available'}</Text>
                    </Box>
                  ) : (
                    <Text>No quiz data available</Text>
                  )}
                  <Text><strong>Facts:</strong> {player.profile_data.facts}</Text>
                  <Text><strong>Narrative:</strong> {player.profile_data.narrative}</Text>
                </Box>
              ) : (
                <Text>No profile data available</Text>
              )}
              <Text><strong>Bank Account:</strong> {typeof player.bank_account === 'string' ? player.bank_account : `${player.bank_account.bank_name}, Balance: ${player.bank_account.balance}`}</Text>
            </ListItem>
          ))}
        </List>
      </Box>
    </VStack>
  );
};

export default PlayerList; 