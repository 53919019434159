import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Spinner,
  Text,
  Container,
  useColorModeValue,
} from '@chakra-ui/react';
import api from '../api/api';

const CSX = () => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalCount, setTotalCount] = useState(0);

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await api.get('/companies');
        // Sort companies by name
        const sortedCompanies = [...response.data.companies].sort((a, b) => 
          a.name.localeCompare(b.name)
        );
        setCompanies(sortedCompanies);
        setTotalCount(response.data.total_count);
      } catch (err) {
        setError('Failed to fetch companies data');
        console.error('Error fetching companies:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanies();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" color="teal.500" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={4}>
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Container maxW="container.xl" py={8}>
      <Heading mb={6}>CSX Companies</Heading>
      <Text mb={4}>Total Companies: {totalCount}</Text>
      <Box overflowX="auto">
        <Table variant="simple" bg={bgColor} borderWidth="1px" borderColor={borderColor}>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Market Sector</Th>
              <Th>Founder</Th>
              <Th>CEO</Th>
              <Th>IPO Price</Th>
              <Th>Treasury Shares</Th>
              <Th>Shares Outstanding</Th>
            </Tr>
          </Thead>
          <Tbody>
            {companies.map((company) => (
              <Tr key={company.id}>
                <Td fontWeight="bold">{company.name}</Td>
                <Td>{company.market_sector}</Td>
                <Td>{company.founder}</Td>
                <Td>{company.executive_officer}</Td>
                <Td>${company.ipo_price}</Td>
                <Td>{company.treasury_shares}</Td>
                <Td>{company.shares_outstanding}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Container>
  );
};

export default CSX; 