import api from '../api/api';

/**
 * Fetches all edges for a specific node.
 * @param {string|number} nodeId - The ID of the node to fetch edges for.
 * @returns {Promise<Array>} - A promise that resolves to an array of edges.
 */
export const fetchEdgesByNodeId = async (nodeId) => {
  try {
    const response = await api.get(`/nodes/${nodeId}/edges`);
    return response.data;
  } catch (error) {
    console.error('Error fetching edges:', error);
    throw error;
  }
};

/**
 * Creates a new edge in the API.
 * @param {Object} edgeData - The edge data to be created.
 * @param {number} edgeData.story_id - ID of the story this edge belongs to.
 * @param {number} edgeData.from_node_id - ID of the starting node.
 * @param {number} edgeData.to_node_id - ID of the ending node.
 * @param {string} edgeData.label - The label for this edge (e.g., "accept", "decline").
 * @param {Object} [edgeData.conditions] - Optional conditions for this edge.
 * @param {Object} [edgeData.effects] - Optional effects for this edge.
 * @param {number} [edgeData.order] - Optional order for sorting edges.
 * @returns {Promise<Object>} - A promise that resolves to the created edge.
 */
export const createEdge = async (edgeData) => {
  try {
    const response = await api.post('/edges', { edge: edgeData });
    return response.data;
  } catch (error) {
    console.error('Error creating edge:', error);
    throw error;
  }
};

/**
 * Updates an existing edge in the API.
 * @param {string|number} id - The ID of the edge to update.
 * @param {Object} updatedData - The updated edge data.
 * @param {string} [updatedData.label] - The updated label.
 * @param {Object} [updatedData.conditions] - The updated conditions.
 * @param {Object} [updatedData.effects] - The updated effects.
 * @param {number} [updatedData.order] - The updated order.
 * @returns {Promise<Object>} - A promise that resolves to the updated edge.
 */
export const updateEdge = async (id, updatedData) => {
  try {
    const response = await api.patch(`/edges/${id}`, { edge: updatedData });
    return response.data;
  } catch (error) {
    console.error('Error updating edge:', error);
    throw error;
  }
};

/**
 * Deletes an edge from the API.
 * @param {string|number} id - The ID of the edge to delete.
 * @returns {Promise<void>} - A promise that resolves when the edge is deleted.
 */
export const deleteEdge = async (id) => {
  try {
    await api.delete(`/edges/${id}`);
  } catch (error) {
    console.error('Error deleting edge:', error);
    throw error;
  }
}; 