import React, { useState, useEffect } from 'react';
import { Box, Heading, List, ListItem, Text, Divider, Spinner } from '@chakra-ui/react';
import api from '../api/api';

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const News = () => {
  const [news, setNews] = useState([]);

  const fetchNews = async () => {
    try {
      const response = await api.get('/commodities/news_narratives/current');
      const newsWithTitles = response.data.map(item => {
        const titleMatch = item.content.match(/\*\*(.*?)\*\*/);
        const title = titleMatch ? titleMatch[1] : 'No Title';
        const contentWithoutTitle = item.content.replace(/\*\*(.*?)\*\*/, '').trim();
        return { ...item, title, content: contentWithoutTitle };
      }).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setNews(newsWithTitles);
    } catch (error) {
      console.error('Error fetching news narratives:', error);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);


  return (
    news.length === 0 ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}><Spinner size="xl" /></div>
    ) : (
    <Box bg="#FFFFFF" color="black" height="80vh" maxHeight="80vh" textAlign="center" py={10} px={6} overflowY="auto">
      <Heading as="h1" size="xl" mb={5} color="#071726">
        Latest News
      </Heading>
      <List spacing={5}>
        {news.map((item, index) => (
          <React.Fragment key={item.id}>
            <ListItem mb={5}>
              <Text color="black" fontWeight="bold" fontSize="xl" textAlign="left">{item.title}</Text>
              <Text color="black" fontSize="lg" textAlign="left"><i>{formatDate(item.created_at)}</i></Text>
              <Text color="black" textAlign="left">{item.content}</Text>
            </ListItem>
            {index < news.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Box>
    )
  );
};

export default News;
