import api from '../api/api';

/**
 * Fetches all nodes from the API.
 * @returns {Promise<Array>} - A promise that resolves to an array of nodes.
 */
export const fetchNodes = async () => {
  try {
    const response = await api.get('/nodes');
    return response.data;
  } catch (error) {
    console.error('Error fetching nodes:', error);
    throw error;
  }
};

/**
 * Fetches a specific node by ID.
 * @param {string|number} id - The ID of the node to fetch.
 * @returns {Promise<Object>} - A promise that resolves to the node data.
 */
export const fetchNodeById = async (id) => {
  try {
    const response = await api.get(`/nodes/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching node:', error);
    throw error;
  }
};

/**
 * Fetches nodes by story ID.
 * @param {string|number} storyId - The ID of the story to fetch nodes for.
 * @returns {Promise<Array>} - A promise that resolves to an array of nodes.
 */
export const fetchNodeByStoryId = async (storyId) => {
  try {
    const response = await api.get(`/stories/${storyId}/nodes`);
    return response.data;
  } catch (error) {
    console.error('Error fetching nodes by story ID:', error);
    throw error;
  }
};

/**
 * Creates a new node in the API.
 * @param {Object} nodeData - The node data to be created.
 * @param {string} nodeData.name - The name of the node.
 * @param {string} nodeData.display_text - The text to be displayed for this node.
 * @param {number} [nodeData.story_id] - Optional ID of the story this node belongs to.
 * @param {Object} [nodeData.metadata] - Optional additional metadata for the node.
 * @returns {Promise<Object>} - A promise that resolves to the created node.
 */
export const createNode = async (nodeData) => {
  try {
    const response = await api.post('/nodes', { node: nodeData });
    return response.data;
  } catch (error) {
    console.error('Error creating node:', error);
    throw error;
  }
};

/**
 * Updates an existing node in the API.
 * @param {string|number} id - The ID of the node to update.
 * @param {Object} updatedData - The updated node data.
 * @param {string} [updatedData.name] - The updated name of the node.
 * @param {string} [updatedData.display_text] - The updated display text.
 * @param {number} [updatedData.story_id] - The updated story ID.
 * @param {Object} [updatedData.metadata] - The updated metadata.
 * @returns {Promise<Object>} - A promise that resolves to the updated node.
 */
export const updateNode = async (id, updatedData) => {
  try {
    const response = await api.patch(`/nodes/${id}`, { node: updatedData });
    return response.data;
  } catch (error) {
    console.error('Error updating node:', error);
    throw error;
  }
};

/**
 * Deletes a node from the API.
 * @param {string|number} id - The ID of the node to delete.
 * @returns {Promise<void>} - A promise that resolves when the node is deleted.
 */
export const deleteNode = async (id) => {
  try {
    await api.delete(`/nodes/${id}`);
  } catch (error) {
    console.error('Error deleting node:', error);
    throw error;
  }
}; 