import React, { useState } from 'react';
import { Box, VStack, Image, Button, Text, useToast } from '@chakra-ui/react';
import api from '../api/api';

const Paroma = () => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleUnlock = async () => {
    setIsLoading(true);
    try {
      await api.post('/unlock_paroma', { cost: 100000 });
      toast({
        title: "Paroma Unlocked!",
        description: "Welcome to the future of AI companionship.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Unable to unlock",
        description: error.response?.data?.message || "Insufficient funds",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <VStack spacing={6} align="center" justify="center" height="100%" p={4}>
      <Image 
        src="/Meet_Paroma_solo.png" 
        alt="Paroma AI Assistant"
        maxW="400px"
        borderRadius="md"
      />
      <Text fontSize="xl" color="white" textAlign="center">
        Meet Paroma, your personal AI assistant
      </Text>
      <Button
        colorScheme="teal"
        size="lg"
        onClick={handleUnlock}
        isLoading={isLoading}
        loadingText="Processing"
      >
        Unlock Paroma (100,000 Common Sovereigns)
      </Button>
    </VStack>
  );
};

export default Paroma; 