import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import NexusLayout from './components/NexusLayout';
import ProtectedRoute from './components/ProtectedRoute';
import LandingPage from './components/LandingPage';
import LoginPage from './components/LoginPage';
import Console from './components/GameConsole';
import Comms from './components/Comms';
import Compendium from './components/Compendium';

import AdminSection from './components/AdminSection';
// Uncomment and import the remaining components as needed
import Characters from './components/Characters';
// import Chats from './components/Chats';
// import Messages from './components/Messages';
import Commodities from './components/Commodities';
import ChatGptPrompts from './components/ChatGptPrompts';
import EditChatGptPrompt from './components/EditChatGptPrompt'; // Import the new component
import News from './components/News';
import SearchCanon from './components/SearchCanon';
import EditCanonPost from './components/EditCanonPost'; // Import the new component
import CanonResultDetails from './components/CanonResultDetails'; // Import the new component
import GenerateCanonRevision from './components/GenerateCanonRevision'; // Import the GenerateCanonRevision component
// import Players from './components/Players';
import CanonArticles from './components/CanonArticles';
import CanonDraftArticles from './components/CanonDraftArticles'; // Import the new component
import AddCanonEntry from './components/AddCanonEntry'; // Import the AddCanonEntry component
import CanonRandomDraftArticles from './components/CanonRandomDraftArticles'; // Import the CanonRandomDraftArticles component
import CanonRandomPublishedArticles from './components/CanonRandomPublishedArticles'; // Import the CanonRandomPublishedArticles component
import PlayerList from './components/admin/PlayerList'; // Import the PlayerList component
import AdminStories from './components/admin/AdminStories'; // Import the AdminStories component
import StoryDetail from './components/admin/StoryDetail'; // Import the StoryDetail component
import BigThink from './components/bigThink'; // Import the BigThink component
import Paroma from './components/Paroma';
import CSX from './components/CSX';

const App = () => {
  const hostname = window.location.hostname;

  if (hostname === 'prlx.sbs') {
    return <Layout><LandingPage /></Layout>;
  } else if (hostname === 'nexus.paraluxunlimited.com' || hostname === 'localhost') {
    return (
      <NexusLayout>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/console" element={<ProtectedRoute><Console /></ProtectedRoute>} />
          <Route path="/comms" element={<ProtectedRoute><Comms /></ProtectedRoute>} />
          <Route path="/admin" element={<ProtectedRoute><AdminSection /></ProtectedRoute>} />
          <Route path="/admin/stories" element={<ProtectedRoute><AdminStories /></ProtectedRoute>} />
          <Route path="/admin/stories/:storyId" element={<ProtectedRoute><StoryDetail /></ProtectedRoute>} />
          {/* Uncomment and add the remaining routes as needed */}
          <Route path="/admin/characters" element={<ProtectedRoute><Characters /></ProtectedRoute>} />
          {/* <Route path="/admin/chats" element={<Chats />} /> */}
          {/* <Route path="/admin/messages" element={<Messages />} /> */}
          <Route path="/commodities" element={<ProtectedRoute><Commodities /></ProtectedRoute>} />
          <Route path="/compendium" element={<ProtectedRoute><Compendium /></ProtectedRoute>} />
          <Route path="/bigthink" element={<ProtectedRoute><BigThink /></ProtectedRoute>} />
          <Route path="/admin/chat_gpt_prompts" element={<ProtectedRoute><ChatGptPrompts /></ProtectedRoute>} />
          <Route path="/admin/chat_gpt_prompts/edit/:id" element={<ProtectedRoute><EditChatGptPrompt /></ProtectedRoute>} /> {/* New route */}
          {/* <Route path="/admin/news_narratives" element={<NewsNarratives />} /> */}
          <Route path="/news" element={<ProtectedRoute><News /></ProtectedRoute>} />
          <Route path="/admin/search_canon" element={<ProtectedRoute><SearchCanon /></ProtectedRoute>} />
          <Route path="/admin/canon_articles" element={<ProtectedRoute><CanonArticles /></ProtectedRoute>} />
          <Route path="/admin/canon_draft_articles" element={<ProtectedRoute><CanonDraftArticles /></ProtectedRoute>} /> {/* New route */}
          <Route path="/admin/search_canon/edit/:id" element={<ProtectedRoute><EditCanonPost /></ProtectedRoute>} />
          {/* <Route path="/admin/players" element={<Players />} /> */}
          <Route path="/admin/search_canon/details/:id" element={<ProtectedRoute><CanonResultDetails /></ProtectedRoute>} />
          <Route path="/admin/add_canon_entry" element={<ProtectedRoute><AddCanonEntry /></ProtectedRoute>} /> {/* New route for AddCanonEntry */}
          <Route path="/admin/generate_canon_revision/:id" element={<ProtectedRoute><GenerateCanonRevision /></ProtectedRoute>} /> {/* New route for GenerateCanonRevision */}
          <Route path="/admin/canon_random_draft_articles" element={<ProtectedRoute><CanonRandomDraftArticles /></ProtectedRoute>} /> {/* New route for CanonRandomDraftArticles */}
          <Route path="/admin/canon_random_published_articles" element={<ProtectedRoute><CanonRandomPublishedArticles /></ProtectedRoute>} />
          <Route path="/admin/players" element={<ProtectedRoute><PlayerList /></ProtectedRoute>} />
          <Route path="/paroma" element={<ProtectedRoute><Paroma /></ProtectedRoute>} />
          <Route path="/csx" element={<ProtectedRoute><CSX /></ProtectedRoute>} />
        </Routes>
      </NexusLayout>
    );
  } else {
    return <div>System error: Nothing to show.</div>; // Optional: A default landing page
  }
  
  
};

export default App;
